@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Inter;
    font-weight: 400;
    src: url(./assets/fonts/Inter-Regular.woff2) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: Inter;
    font-weight: 500;
    src: url(./assets/fonts/Inter-Medium.woff2) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: Inter;
    font-weight: 600;
    src: url(./assets/fonts/Inter-SemiBold.woff2) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: Inter;
    font-weight: 700;
    src: url(./assets/fonts/Inter-Bold.woff2) format('woff2');
    font-display: swap;
  }
  .selectable-selectbox {
    z-index: 9000;
    position: absolute;
    cursor: default;
    background: none;
    border: 1px dashed grey;
  }

  .last-no-border li:last-child {
    border-bottom: none;
  }
  .navigation__list li:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .navigation__list li:last-child {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  tr:last-child td {
    border-bottom: 0;
  }
  tr:not(:first-of-type) td {
    user-select: none;
  }
  tr:not(:first-of-type) td:not(:first-of-type) {
    user-select: all;
    padding: 6px;
    cursor: pointer;
  }
  th:last-child {
    border-right: 0;
  }

  .c-toast:before {
    content: '';
    height: 100%;
    width: 3px;
    position: absolute;
    left: 0;
    top: 0;
    @apply dark:bg-secondary-light bg-secondary;
  }

  .g-toast:before {
    content: '';
    height: 100%;
    width: 3px;
    position: absolute;
    left: 0;
    top: 0;
    @apply dark:bg-green-200 bg-green-500;
  }

  .mainContent {
    transition: margin-left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    /* transition: margin-left 0.3s cubic-bezier(0.47, 0, 0.745, 0.715); */
    animation: fadeIn 0.5s ease-out;
  }

  .tablePrint {
    padding: 10px;
  }
  .ui-selected {
    @apply bg-secondary hover:bg-secondary !important;
  }
  .ui-selecting {
    @apply bg-secondary-light !important;
  }
}

@layer utilities {
  @variants responsive {
    .clip-path-parallelogram {
      clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%);
    }
    .clip-path-parallelogram-none {
      clip-path: none;
    }
  }
}
@layer components {
  /* .modal-backdrop {
    @apply ;
  } */
}
